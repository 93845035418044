// components/Depoimentos.js
import React from "react";
import pat1 from "./images/pat-1.png";
import edsongomes from "./images/clientes/edsongomes.jpg";
import rafaelsilva from "./images/clientes/rafaelsilva.jpg";
import giovannacarepa from "./images/clientes/giovannacarepa.jpg";
import joaovitor from "./images/clientes/joaovitor.gif";
import gloriaoliveira from "./images/clientes/gloriaoliveira.jpeg";
import milenawinck from "./images/clientes/milenawinck.jpg";
import alinecandido from "./images/clientes/alinecandido.jpg";

const Depoimentos = () => {
  return (
    <section
      className="elementor-section elementor-top-section elementor-element elementor-element-46a4d25 elementor-section-full_width lui-gradient-center elementor-section-height-default"
      data-id="46a4d25"
      data-element_type="section"
      id="testimonials-section"
    >
      <div className="elementor-container elementor-column-gap-no">
        <div
          className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-9659e68"
          data-id="9659e68"
          data-element_type="column"
        >
          <div className="elementor-widget-wrap elementor-element-populated">
            <section
              className="elementor-section elementor-inner-section elementor-element elementor-element-85f6b04 elementor-section-boxed elementor-section-height-default"
              data-id="85f6b04"
              data-element_type="section"
            >
              <div className="elementor-container elementor-column-gap-default">
                <div
                  className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-84e6618"
                  data-id="84e6618"
                  data-element_type="column"
                >
                  <div className="elementor-widget-wrap elementor-element-populated">
                    <div
                      className="elementor-element elementor-element-8bf97d1 elementor-widget elementor-widget-ti-section-heading"
                      data-id="8bf97d1"
                      data-element_type="widget"
                      data-widget_type="ti-section-heading.default"
                    >
                      <div className="elementor-widget-container">
                        <div className="m-titles">
                          <h2
                            className="m-title splitting-text-anim-1 scroll-animate"
                            data-splitting="words"
                            data-animate="active"
                          >
                            <span>Depoimentos</span>
                          </h2>
                          <div
                            className="m-subtitle splitting-text-anim-1 scroll-animate"
                            data-splitting="words"
                            data-animate="active"
                          >
                            <span>
                              O que nossos <b>clientes</b> dizem
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section
              className="elementor-section elementor-inner-section elementor-element elementor-element-a41d018 v-line v-line-right elementor-section-boxed elementor-section-height-default"
              data-id="a41d018"
              data-element_type="section"
            >
              <div className="elementor-container elementor-column-gap-default">
                <div
                  className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-545a67f"
                  data-id="545a67f"
                  data-element_type="column"
                >
                  <div className="elementor-widget-wrap elementor-element-populated">
                    <div
                      className="elementor-element elementor-element-1081419 elementor-widget elementor-widget-ti-testimonials"
                      data-id="1081419"
                      data-element_type="widget"
                      data-widget_type="ti-testimonials.default"
                    >
                      <div className="elementor-widget-container">
                        <div
                          className="swiper-container js-testimonials scrolla-element-anim-1 scroll-animate"
                          data-animate="active"
                        >
                          <div className="swiper-wrapper">
                            <div className="swiper-slide">
                              <div className="testimonials-item">
                                <div className="image">
                                  <noscript>
                                    <img
                                      decoding="async"
                                      src={edsongomes}
                                      alt="Edson Gomes"
                                    />
                                  </noscript>
                                  <img
                                    className="lazyload"
                                    decoding="async"
                                    src="data:image/svg+xml,%3Csvg%20xmlns=%22http://www.w3.org/2000/svg%22%20viewBox=%220%200%20210%20140%22%3E%3C/svg%3E"
                                    data-src={edsongomes}
                                    alt="Edson Gomes"
                                  />
                                  <div className="icon">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="44px"
                                      height="34px"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        strokeWidth="2px"
                                        stroke="rgb(0, 0, 0)"
                                        fill="rgb(41, 165, 135)"
                                        d="M17.360,8.325 C15.490,5.563 11.616,4.762 8.705,6.536 C6.901,7.635 5.815,9.533 5.826,11.567 C5.828,14.854 8.637,17.516 12.101,17.515 C13.290,17.513 14.456,17.192 15.460,16.587 C14.967,17.975 14.049,19.457 12.537,20.942 C11.934,21.533 11.951,22.476 12.574,23.048 C13.198,23.619 14.192,23.604 14.794,23.012 C20.384,17.515 19.658,11.539 17.360,8.333 L17.360,8.325 ZM32.407,8.325 C30.538,5.563 26.663,4.762 23.752,6.536 C21.949,7.635 20.863,9.533 20.873,11.567 C20.875,14.854 23.685,17.516 27.148,17.515 C28.338,17.513 29.503,17.192 30.508,16.587 C30.015,17.975 29.097,19.457 27.585,20.942 C26.982,21.533 26.999,22.476 27.622,23.048 C28.245,23.619 29.239,23.604 29.842,23.012 C35.432,17.515 34.706,11.539 32.407,8.333 L32.407,8.325 Z"
                                      ></path>
                                    </svg>
                                  </div>
                                </div>
                                <div className="text lui-text">
                                  <div>
                                    <p>
                                      Gerenciar um negócio não é fácil, por isso
                                      confiei ao talentoso Diego Melo a
                                      construção do meu website
                                      cagemercapacitacao. Sua expertise e
                                      parceria foram essenciais para aprimorar
                                      esse projeto. Agradeço pela colaboração,
                                      Diego! 👏🏾🚀
                                    </p>
                                  </div>
                                </div>
                                <div className="info">
                                  <h6 className="name">
                                    <span>Edson Gomes</span>
                                  </h6>
                                  <div className="author">
                                    <span>Técnico em emegencista</span>
                                  </div>
                                </div>
                                <div
                                  className="lazyload bg-img"
                                  data-bg={pat1}
                                  style={{
                                    backgroundImage:
                                      "url(data:image/svg+xml,%3Csvg%20xmlns=%22http://www.w3.org/2000/svg%22%20viewBox=%220%200%20500%20300%22%3E%3C/svg%3E)",
                                  }}
                                ></div>
                              </div>
                            </div>

                            <div className="swiper-slide">
                              <div className="testimonials-item">
                                <div className="image">
                                  <noscript>
                                    <img
                                      decoding="async"
                                      src={rafaelsilva}
                                      alt="Rafael Silva"
                                    />
                                  </noscript>
                                  <img
                                    className="lazyload"
                                    decoding="async"
                                    src="data:image/svg+xml,%3Csvg%20xmlns=%22http://www.w3.org/2000/svg%22%20viewBox=%220%200%20210%20140%22%3E%3C/svg%3E"
                                    data-src={rafaelsilva}
                                    alt="Rafael Silva"
                                  />
                                  <div className="icon">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="44px"
                                      height="34px"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        strokeWidth="2px"
                                        stroke="rgb(0, 0, 0)"
                                        fill="rgb(41, 165, 135)"
                                        d="M17.360,8.325 C15.490,5.563 11.616,4.762 8.705,6.536 C6.901,7.635 5.815,9.533 5.826,11.567 C5.828,14.854 8.637,17.516 12.101,17.515 C13.290,17.513 14.456,17.192 15.460,16.587 C14.967,17.975 14.049,19.457 12.537,20.942 C11.934,21.533 11.951,22.476 12.574,23.048 C13.198,23.619 14.192,23.604 14.794,23.012 C20.384,17.515 19.658,11.539 17.360,8.333 L17.360,8.325 ZM32.407,8.325 C30.538,5.563 26.663,4.762 23.752,6.536 C21.949,7.635 20.863,9.533 20.873,11.567 C20.875,14.854 23.685,17.516 27.148,17.515 C28.338,17.513 29.503,17.192 30.508,16.587 C30.015,17.975 29.097,19.457 27.585,20.942 C26.982,21.533 26.999,22.476 27.622,23.048 C28.245,23.619 29.239,23.604 29.842,23.012 C35.432,17.515 34.706,11.539 32.407,8.333 L32.407,8.325 Z"
                                      ></path>
                                    </svg>
                                  </div>
                                </div>
                                <div className="text lui-text">
                                  <div>
                                    <p>
                                      Obrigado você é incrível demais, indico
                                      sempre. Parabéns por tanto
                                      profissionalismo, Diego Melo, por captar a
                                      nossa essência e fazer tudo ficar com a
                                      nossa cara e gostei muito do site que você
                                      fez.
                                    </p>
                                  </div>
                                </div>
                                <div className="info">
                                  <h6 className="name">
                                    <span>Rafael Silva</span>
                                  </h6>
                                  <div className="author">
                                    <span>Cinematográfico</span>
                                  </div>
                                </div>
                                <div
                                  className="lazyload bg-img"
                                  data-bg={pat1}
                                  style={{
                                    backgroundImage:
                                      "url(data:image/svg+xml,%3Csvg%20xmlns=%22http://www.w3.org/2000/svg%22%20viewBox=%220%200%20500%20300%22%3E%3C/svg%3E)",
                                  }}
                                ></div>
                              </div>
                            </div>

                            <div className="swiper-slide">
                              <div className="testimonials-item">
                                <div className="image">
                                  <noscript>
                                    <img
                                      decoding="async"
                                      src={giovannacarepa}
                                      alt="Giovanna Carepa"
                                    />
                                  </noscript>
                                  <img
                                    className="lazyload"
                                    decoding="async"
                                    src="data:image/svg+xml,%3Csvg%20xmlns=%22http://www.w3.org/2000/svg%22%20viewBox=%220%200%20210%20140%22%3E%3C/svg%3E"
                                    data-src={giovannacarepa}
                                    alt="Giovanna Carepa"
                                  />
                                  <div className="icon">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="44px"
                                      height="34px"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        strokeWidth="2px"
                                        stroke="rgb(0, 0, 0)"
                                        fill="rgb(41, 165, 135)"
                                        d="M17.360,8.325 C15.490,5.563 11.616,4.762 8.705,6.536 C6.901,7.635 5.815,9.533 5.826,11.567 C5.828,14.854 8.637,17.516 12.101,17.515 C13.290,17.513 14.456,17.192 15.460,16.587 C14.967,17.975 14.049,19.457 12.537,20.942 C11.934,21.533 11.951,22.476 12.574,23.048 C13.198,23.619 14.192,23.604 14.794,23.012 C20.384,17.515 19.658,11.539 17.360,8.333 L17.360,8.325 ZM32.407,8.325 C30.538,5.563 26.663,4.762 23.752,6.536 C21.949,7.635 20.863,9.533 20.873,11.567 C20.875,14.854 23.685,17.516 27.148,17.515 C28.338,17.513 29.503,17.192 30.508,16.587 C30.015,17.975 29.097,19.457 27.585,20.942 C26.982,21.533 26.999,22.476 27.622,23.048 C28.245,23.619 29.239,23.604 29.842,23.012 C35.432,17.515 34.706,11.539 32.407,8.333 L32.407,8.325 Z"
                                      ></path>
                                    </svg>
                                  </div>
                                </div>
                                <div className="text lui-text">
                                  <div>
                                    <p>
                                      Primeiramente gostaria de agradecer a todo
                                      apoio dado e paciência para conosco. Só
                                      temos a agradecer pelo site. Ficou muito
                                      bom. Parabéns Diego Melo.
                                    </p>
                                  </div>
                                </div>
                                <div className="info">
                                  <h6 className="name">
                                    <span>Giovanna Carepa</span>
                                  </h6>
                                  <div className="author">
                                    <span>Streamer</span>
                                  </div>
                                </div>
                                <div
                                  className="lazyload bg-img"
                                  data-bg={pat1}
                                  style={{
                                    backgroundImage:
                                      "url(data:image/svg+xml,%3Csvg%20xmlns=%22http://www.w3.org/2000/svg%22%20viewBox=%220%200%20500%20300%22%3E%3C/svg%3E)",
                                  }}
                                ></div>
                              </div>
                            </div>

                            <div className="swiper-slide">
                              <div className="testimonials-item">
                                <div className="image">
                                  <noscript>
                                    <img
                                      decoding="async"
                                      src={joaovitor}
                                      alt="João Vitor"
                                    />
                                  </noscript>
                                  <img
                                    className="lazyload"
                                    decoding="async"
                                    src="data:image/svg+xml,%3Csvg%20xmlns=%22http://www.w3.org/2000/svg%22%20viewBox=%220%200%20210%20140%22%3E%3C/svg%3E"
                                    data-src={joaovitor}
                                    alt="João Vitor"
                                  />
                                  <div className="icon">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="44px"
                                      height="34px"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        strokeWidth="2px"
                                        stroke="rgb(0, 0, 0)"
                                        fill="rgb(41, 165, 135)"
                                        d="M17.360,8.325 C15.490,5.563 11.616,4.762 8.705,6.536 C6.901,7.635 5.815,9.533 5.826,11.567 C5.828,14.854 8.637,17.516 12.101,17.515 C13.290,17.513 14.456,17.192 15.460,16.587 C14.967,17.975 14.049,19.457 12.537,20.942 C11.934,21.533 11.951,22.476 12.574,23.048 C13.198,23.619 14.192,23.604 14.794,23.012 C20.384,17.515 19.658,11.539 17.360,8.333 L17.360,8.325 ZM32.407,8.325 C30.538,5.563 26.663,4.762 23.752,6.536 C21.949,7.635 20.863,9.533 20.873,11.567 C20.875,14.854 23.685,17.516 27.148,17.515 C28.338,17.513 29.503,17.192 30.508,16.587 C30.015,17.975 29.097,19.457 27.585,20.942 C26.982,21.533 26.999,22.476 27.622,23.048 C28.245,23.619 29.239,23.604 29.842,23.012 C35.432,17.515 34.706,11.539 32.407,8.333 L32.407,8.325 Z"
                                      ></path>
                                    </svg>
                                  </div>
                                </div>
                                <div className="text lui-text">
                                  <div>
                                    <p>
                                      O Diego é um ótimo profissional, além do
                                      excelente tratamento ajudou no design e
                                      contribuiu fazendo o site da Codify
                                      Community.
                                    </p>
                                  </div>
                                </div>
                                <div className="info">
                                  <h6 className="name">
                                    <span>João Vitor</span>
                                  </h6>
                                  <div className="author">
                                    <span>Fundador da Codify Community</span>
                                  </div>
                                </div>
                                <div
                                  className="lazyload bg-img"
                                  data-bg={pat1}
                                  style={{
                                    backgroundImage:
                                      "url(data:image/svg+xml,%3Csvg%20xmlns=%22http://www.w3.org/2000/svg%22%20viewBox=%220%200%20500%20300%22%3E%3C/svg%3E)",
                                  }}
                                ></div>
                              </div>
                            </div>

                            <div className="swiper-slide">
                              <div className="testimonials-item">
                                <div className="image">
                                  <noscript>
                                    <img
                                      decoding="async"
                                      src={gloriaoliveira}
                                      alt="Gloria Oliveira"
                                    />
                                  </noscript>
                                  <img
                                    className="lazyload"
                                    decoding="async"
                                    src="data:image/svg+xml,%3Csvg%20xmlns=%22http://www.w3.org/2000/svg%22%20viewBox=%220%200%20210%20140%22%3E%3C/svg%3E"
                                    data-src={gloriaoliveira}
                                    alt="Gloria Oliveira"
                                  />
                                  <div className="icon">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="44px"
                                      height="34px"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        strokeWidth="2px"
                                        stroke="rgb(0, 0, 0)"
                                        fill="rgb(41, 165, 135)"
                                        d="M17.360,8.325 C15.490,5.563 11.616,4.762 8.705,6.536 C6.901,7.635 5.815,9.533 5.826,11.567 C5.828,14.854 8.637,17.516 12.101,17.515 C13.290,17.513 14.456,17.192 15.460,16.587 C14.967,17.975 14.049,19.457 12.537,20.942 C11.934,21.533 11.951,22.476 12.574,23.048 C13.198,23.619 14.192,23.604 14.794,23.012 C20.384,17.515 19.658,11.539 17.360,8.333 L17.360,8.325 ZM32.407,8.325 C30.538,5.563 26.663,4.762 23.752,6.536 C21.949,7.635 20.863,9.533 20.873,11.567 C20.875,14.854 23.685,17.516 27.148,17.515 C28.338,17.513 29.503,17.192 30.508,16.587 C30.015,17.975 29.097,19.457 27.585,20.942 C26.982,21.533 26.999,22.476 27.622,23.048 C28.245,23.619 29.239,23.604 29.842,23.012 C35.432,17.515 34.706,11.539 32.407,8.333 L32.407,8.325 Z"
                                      ></path>
                                    </svg>
                                  </div>
                                </div>
                                <div className="text lui-text">
                                  <div>
                                    <p>
                                      Diego é um profissional excepcional. Ele
                                      tem muito conhecimento e foi capaz de me
                                      ajudar da melhor forma possível. Além
                                      disso, ele me passou diversas informações
                                      e dicas importantes a respeito de
                                      segurança no Windows. É um profissional
                                      extremamente inteligente, dedicado e
                                      solícito. Com certeza ganhou uma cliente
                                      fiel.
                                    </p>
                                  </div>
                                </div>
                                <div className="info">
                                  <h6 className="name">
                                    <span>Gloria Oliveira</span>
                                  </h6>
                                  <div className="author">
                                    <span>Arquiteta</span>
                                  </div>
                                </div>
                                <div
                                  className="lazyload bg-img"
                                  data-bg={pat1}
                                  style={{
                                    backgroundImage:
                                      "url(data:image/svg+xml,%3Csvg%20xmlns=%22http://www.w3.org/2000/svg%22%20viewBox=%220%200%20500%20300%22%3E%3C/svg%3E)",
                                  }}
                                ></div>
                              </div>
                            </div>

                            <div className="swiper-slide">
                              <div className="testimonials-item">
                                <div className="image">
                                  <noscript>
                                    <img
                                      decoding="async"
                                      src={milenawinck}
                                      alt="Milena Winck"
                                    />
                                  </noscript>
                                  <img
                                    className="lazyload"
                                    decoding="async"
                                    src="data:image/svg+xml,%3Csvg%20xmlns=%22http://www.w3.org/2000/svg%22%20viewBox=%220%200%20210%20140%22%3E%3C/svg%3E"
                                    data-src={milenawinck}
                                    alt="Milena Winck"
                                  />
                                  <div className="icon">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="44px"
                                      height="34px"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        strokeWidth="2px"
                                        stroke="rgb(0, 0, 0)"
                                        fill="rgb(41, 165, 135)"
                                        d="M17.360,8.325 C15.490,5.563 11.616,4.762 8.705,6.536 C6.901,7.635 5.815,9.533 5.826,11.567 C5.828,14.854 8.637,17.516 12.101,17.515 C13.290,17.513 14.456,17.192 15.460,16.587 C14.967,17.975 14.049,19.457 12.537,20.942 C11.934,21.533 11.951,22.476 12.574,23.048 C13.198,23.619 14.192,23.604 14.794,23.012 C20.384,17.515 19.658,11.539 17.360,8.333 L17.360,8.325 ZM32.407,8.325 C30.538,5.563 26.663,4.762 23.752,6.536 C21.949,7.635 20.863,9.533 20.873,11.567 C20.875,14.854 23.685,17.516 27.148,17.515 C28.338,17.513 29.503,17.192 30.508,16.587 C30.015,17.975 29.097,19.457 27.585,20.942 C26.982,21.533 26.999,22.476 27.622,23.048 C28.245,23.619 29.239,23.604 29.842,23.012 C35.432,17.515 34.706,11.539 32.407,8.333 L32.407,8.325 Z"
                                      ></path>
                                    </svg>
                                  </div>
                                </div>
                                <div className="text lui-text">
                                  <div>
                                    <p>
                                      Super recomendo. Sempre precisarei da
                                      ajuda dele para resolver meus problemas
                                      como sempre!
                                    </p>
                                  </div>
                                </div>
                                <div className="info">
                                  <h6 className="name">
                                    <span>Milena Winck</span>
                                  </h6>
                                  <div className="author">
                                    <span>Estudiosa</span>
                                  </div>
                                </div>
                                <div
                                  className="lazyload bg-img"
                                  data-bg={pat1}
                                  style={{
                                    backgroundImage:
                                      "url(data:image/svg+xml,%3Csvg%20xmlns=%22http://www.w3.org/2000/svg%22%20viewBox=%220%200%20500%20300%22%3E%3C/svg%3E)",
                                  }}
                                ></div>
                              </div>
                            </div>

                            <div className="swiper-slide">
                              <div className="testimonials-item">
                                <div className="image">
                                  <noscript>
                                    <img
                                      decoding="async"
                                      src={alinecandido}
                                      alt="Aline Candido"
                                    />
                                  </noscript>
                                  <img
                                    className="lazyload"
                                    decoding="async"
                                    src="data:image/svg+xml,%3Csvg%20xmlns=%22http://www.w3.org/2000/svg%22%20viewBox=%220%200%20210%20140%22%3E%3C/svg%3E"
                                    data-src={alinecandido}
                                    alt="Aline Candido"
                                  />
                                  <div className="icon">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="44px"
                                      height="34px"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        strokeWidth="2px"
                                        stroke="rgb(0, 0, 0)"
                                        fill="rgb(41, 165, 135)"
                                        d="M17.360,8.325 C15.490,5.563 11.616,4.762 8.705,6.536 C6.901,7.635 5.815,9.533 5.826,11.567 C5.828,14.854 8.637,17.516 12.101,17.515 C13.290,17.513 14.456,17.192 15.460,16.587 C14.967,17.975 14.049,19.457 12.537,20.942 C11.934,21.533 11.951,22.476 12.574,23.048 C13.198,23.619 14.192,23.604 14.794,23.012 C20.384,17.515 19.658,11.539 17.360,8.333 L17.360,8.325 ZM32.407,8.325 C30.538,5.563 26.663,4.762 23.752,6.536 C21.949,7.635 20.863,9.533 20.873,11.567 C20.875,14.854 23.685,17.516 27.148,17.515 C28.338,17.513 29.503,17.192 30.508,16.587 C30.015,17.975 29.097,19.457 27.585,20.942 C26.982,21.533 26.999,22.476 27.622,23.048 C28.245,23.619 29.239,23.604 29.842,23.012 C35.432,17.515 34.706,11.539 32.407,8.333 L32.407,8.325 Z"
                                      ></path>
                                    </svg>
                                  </div>
                                </div>
                                <div className="text lui-text">
                                  <div>
                                    <p>
                                      Diego é um excelente profissional, conhece
                                      tudo de informática. Só tenho a agradecer
                                      pela ajuda, dedicação e empenho em
                                      resolver minhas demandas! Super indico,
                                      podem contratar seus serviços sem nenhum
                                      receio, que o resultado será alcançado com
                                      perfeição.
                                    </p>
                                  </div>
                                </div>
                                <div className="info">
                                  <h6 className="name">
                                    <span>Aline Candido</span>
                                  </h6>
                                  <div className="author">
                                    <span>Administrativa</span>
                                  </div>
                                </div>
                                <div
                                  className="lazyload bg-img"
                                  data-bg={pat1}
                                  style={{
                                    backgroundImage:
                                      "url(data:image/svg+xml,%3Csvg%20xmlns=%22http://www.w3.org/2000/svg%22%20viewBox=%220%200%20500%20300%22%3E%3C/svg%3E)",
                                  }}
                                ></div>
                              </div>
                            </div>
                          </div>
                          <div className="swiper-pagination"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <div
              className="elementor-element elementor-element-7ddcc4c elementor-widget elementor-widget-ti-bgtitle"
              data-id="7ddcc4c"
              data-element_type="widget"
              data-widget_type="ti-bgtitle.default"
            >
              <div className="elementor-widget-container">
                <div className="lui-bgtitle">
                  <span>Depoimentos</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Depoimentos;
