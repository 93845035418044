// components/Projetos.js
import React from "react";
import { Link } from "react-router-dom";
import part1 from "./images/pat-1.png";
import justdelivery from "./images/sites/justdelivery.png";
import cagemercapacitacao from "./images/sites/cagemercapacitacao.png";
import codifycommunity from "./images/sites/codifycommunity.png";

const Projetos = () => {
  return (
    <section
      className="elementor-section elementor-top-section elementor-element elementor-element-9448ad8 elementor-section-full_width lui-gradient-center elementor-section-height-default elementor-section-height-default"
      data-id="9448ad8"
      data-element_type="section"
      id="works-section"
    >
      <div className="elementor-container elementor-column-gap-no">
        <div
          className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-50be8b3"
          data-id="50be8b3"
          data-element_type="column"
        >
          <div className="elementor-widget-wrap elementor-element-populated">
            <section
              className="elementor-section elementor-inner-section elementor-element elementor-element-66c2427 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
              data-id="66c2427"
              data-element_type="section"
            >
              <div className="elementor-container elementor-column-gap-default">
                <div
                  className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-bc97f39"
                  data-id="bc97f39"
                  data-element_type="column"
                >
                  <div className="elementor-widget-wrap elementor-element-populated">
                    <div
                      className="elementor-element elementor-element-d096b81 elementor-widget elementor-widget-ti-section-heading"
                      data-id="d096b81"
                      data-element_type="widget"
                      data-widget_type="ti-section-heading.default"
                    >
                      <div className="elementor-widget-container">
                        <div className="m-titles">
                          <h2
                            className="m-title splitting-text-anim-1 scroll-animate words splitting animate__active animate__animated"
                            data-splitting="words"
                            data-animate="active"
                            style={{ "--word-total": 2, visibility: "visible" }}
                          >
                            <span
                              className="word"
                              data-word="Latest"
                              style={{ "--word-index": 0 }}
                            >
                              Projetos
                            </span>
                            <span className="whitespace"></span>
                          </h2>

                          <div
                            className="m-subtitle splitting-text-anim-1 scroll-animate"
                            data-splitting="words"
                            data-animate="active"
                          >
                            <span>
                              Nos <b>últimos projetos</b>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section
              className="elementor-section elementor-inner-section elementor-element elementor-element-8894d20 v-line v-line-right elementor-section-boxed elementor-section-height-default elementor-section-height-default"
              data-id="8894d20"
              data-element_type="section"
            >
              <div className="elementor-container elementor-column-gap-default">
                <div
                  className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-3695cd3"
                  data-id="3695cd3"
                  data-element_type="column"
                >
                  <div className="elementor-widget-wrap elementor-element-populated">
                    <div
                      className="elementor-element elementor-element-2aacff2 elementor-widget elementor-widget-ti-portfolio-module"
                      data-id="2aacff2"
                      data-element_type="widget"
                      data-widget_type="ti-portfolio-module.default"
                    >
                      <div className="elementor-widget-container">
                        <div className="works-box">
                          <div
                            className="works-items works-masonry-items row"
                            style={{
                              position: "relative",
                              height: "1831.46px",
                            }}
                          >
                            <div
                              className="works-col col-xs-12 col-sm-12 col-md-12 col-lg-12 sorting-branding"
                              style={{ position: "absolute", left: 0, top: 0 }}
                            >
                              <div
                                className="works-item scrolla-element-anim-1 scroll-animate animate__active animate__animated"
                                data-animate="active"
                                style={{ visibility: "visible" }}
                              >
                                <a
                                  href="https://justdelivery.netlify.app"
                                  className=""
                                >
                                  <div className="image">
                                    <div className="img">
                                      <noscript>
                                        <img
                                          decoding="async"
                                          src={justdelivery}
                                          alt="Aqui no Delivery nós oferecemos os melhores lanches artesanais da região, com excelente qualidade e te convidamos para experimentar. Nós queremos preparar os melhores lanches para os nosso clientes, trazendo alta qualidade e sabor, oferecendo o melhor da casa. Delicie-se e aproveite. Pegue uma bebida e acima de tudo, relaxe! Agradecemos por sua preferência."
                                        />
                                      </noscript>
                                      <img
                                        className="ls-is-cached lazyloaded"
                                        decoding="async"
                                        src={justdelivery}
                                        data-src={justdelivery}
                                        alt="Aqui no Delivery nós oferecemos os melhores lanches artesanais da região, com excelente qualidade e te convidamos para experimentar. Nós queremos preparar os melhores lanches para os nosso clientes, trazendo alta qualidade e sabor, oferecendo o melhor da casa. Delicie-se e aproveite. Pegue uma bebida e acima de tudo, relaxe! Agradecemos por sua preferência."
                                      />
                                      <span className="overlay"></span>
                                    </div>
                                  </div>
                                </a>
                                <div className="desc">
                                  <span className="category">
                                    19 de fevereiro de 2015
                                  </span>
                                  <h5 className="name">Just Delivery</h5>
                                  <div className="text">
                                    <p>
                                      Aqui no Delivery nós oferecemos os
                                      melhores lanches artesanais da região, com
                                      excelente qualidade e te convidamos para
                                      experimentar. Nós queremos preparar os
                                      melhores lanches para os nosso clientes,
                                      trazendo alta qualidade e sabor,
                                      oferecendo o melhor da casa. Delicie-se e
                                      aproveite. Pegue uma bebida e acima de
                                      tudo, relaxe! Agradecemos por sua
                                      preferência.
                                    </p>
                                  </div>
                                  <a
                                    href="https://justdelivery.netlify.app"
                                    className=""
                                  >
                                    <span className="lnk">Site</span>
                                  </a>
                                </div>
                                <div
                                  className="lazyload bg-img"
                                  data-bg={part1}
                                  style={{
                                    backgroundImage:
                                      'url("./images/pat-1.png")',
                                  }}
                                ></div>
                              </div>
                            </div>

                            <div
                              className="works-col col-xs-12 col-sm-12 col-md-12 col-lg-12 sorting-branding"
                              style={{ position: "absolute", left: 0, top: 0 }}
                            >
                              <div
                                className="works-item scrolla-element-anim-1 scroll-animate animate__active animate__animated"
                                data-animate="active"
                                style={{ visibility: "visible" }}
                              >
                                <a className="">
                                  <div className="image">
                                    <div className="img">
                                      <noscript>
                                        <img
                                          decoding="async"
                                          src={cagemercapacitacao}
                                          alt="Em urgência emergência e resgate."
                                        />
                                      </noscript>
                                      <img
                                        className="ls-is-cached lazyloaded"
                                        decoding="async"
                                        src={cagemercapacitacao}
                                        data-src={cagemercapacitacao}
                                        alt="Em urgência emergência e resgate."
                                      />
                                      <span className="overlay"></span>
                                    </div>
                                  </div>
                                </a>
                                <div className="desc">
                                  <span className="category">
                                    26 de novembro de 2020
                                  </span>
                                  <h5 className="name">Cagemer Capacitação</h5>
                                  <div className="text">
                                    <p>Em urgência emergência e resgate.</p>
                                  </div>
                                  <a className="">
                                    <span className="lnk">Site</span>
                                  </a>
                                </div>
                                <div
                                  className="lazyload bg-img"
                                  data-bg={part1}
                                  style={{
                                    backgroundImage:
                                      'url("./images/pat-1.png")',
                                  }}
                                ></div>
                              </div>
                            </div>

                            <div
                              className="works-col col-xs-12 col-sm-12 col-md-12 col-lg-12 sorting-branding"
                              style={{ position: "absolute", left: 0, top: 0 }}
                            >
                              <div
                                className="works-item scrolla-element-anim-1 scroll-animate animate__active animate__animated"
                                data-animate="active"
                                style={{ visibility: "visible" }}
                              >
                                <a
                                  href="https://codifycommunity.com.br"
                                  className=""
                                >
                                  <div className="image">
                                    <div className="img">
                                      <noscript>
                                        <img
                                          decoding="async"
                                          src={codifycommunity}
                                          alt="É uma comunidade de desenvolvedores que tem como objetivo ajudar iniciantes no mundo da programação."
                                        />
                                      </noscript>
                                      <img
                                        className=" ls-is-cached lazyloaded"
                                        decoding="async"
                                        src={codifycommunity}
                                        data-src={codifycommunity}
                                        alt="É uma comunidade de desenvolvedores que tem como objetivo ajudar iniciantes no mundo da programação."
                                      />
                                      <span className="overlay"></span>
                                    </div>
                                  </div>
                                </a>
                                <div className="desc">
                                  <span className="category">
                                    27 de março de 2021
                                  </span>
                                  <h5 className="name">Codify Community</h5>
                                  <div className="text">
                                    <p>
                                      É uma comunidade de desenvolvedores que
                                      tem como objetivo ajudar iniciantes no
                                      mundo da programação.
                                    </p>
                                  </div>
                                  <a
                                    href="https://codifycommunity.com.br"
                                    className=""
                                  >
                                    <span className="lnk">Site</span>
                                  </a>
                                </div>
                                <div
                                  className="lazyload bg-img"
                                  data-bg={part1}
                                  style={{
                                    backgroundImage:
                                      "url(data:image/svg+xml,%3Csvg%20xmlns=%22http://www.w3.org/2000/svg%22%20viewBox=%220%200%20500%20300%22%3E%3C/svg%3E)",
                                  }}
                                ></div>
                              </div>
                            </div>
                          </div>

                          <div className="load-more-link">
                            <Link
                              to="/projetos"
                              className="btn scrolla-element-anim-1 scroll-animate animate__active animate__animated"
                              data-animate="active"
                              style={{ visibility: "visible" }}
                            >
                              <span>Projetos</span>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      className="elementor-element elementor-element-9c95224 elementor-widget elementor-widget-ti-bgtitle"
                      data-id="9c95224"
                      data-element_type="widget"
                      data-widget_type="ti-bgtitle.default"
                    >
                      <div className="elementor-widget-container">
                        <div className="lui-bgtitle">
                          <span>Projetos</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="v-line-block">
                  <span></span>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Projetos;
